import { Locale } from '@type-declarations/locale';
import { addMinutes } from 'date-fns';
import format from 'date-fns/format';
import { enGB, nl } from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';

const formatDate = ({
  date,
  format: dateFormat = 'd MMM y',
  locale = 'nl',
}: {
  date: string;
  format?: string;
  locale?: Locale;
}) => {
  const loc = {
    en: enGB,
    nl,
  };

  const isoDate = parseISO(date);
  const timezoneOffsetMinutes = isoDate.getTimezoneOffset();
  const adjustedDate = addMinutes(isoDate, timezoneOffsetMinutes);

  return format(adjustedDate, dateFormat, {
    locale: loc[locale],
  });
};

export default formatDate;
