import { Locale } from '@type-declarations/locale';
import { Frequency, PreprDate } from '@type-declarations/prepr';
import formatDate from '@utils/formatDate';
import { differenceInCalendarDays } from 'date-fns';

const translations = {
  nl: {
    until: 't/m',
    and: 'en',
    andMore: 'en meer',
    daily: 'Dagelijks',
    weekly: 'Wekelijks',
    monthly: 'Maandelijks',
    eventPassed: 'Dit evenement is verlopen',
  },
  en: {
    until: 'until',
    and: 'and',
    andMore: 'and more',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    eventPassed: 'This event has expired',
  },
} as const;

const getFrequencyTranslation = (frequency: Frequency, locale: Locale) => {
  switch (frequency) {
    case 'DAILY':
      return translations[locale].daily;
    case 'WEEKLY':
      return translations[locale].weekly;
    case 'MONTHLY':
      return translations[locale].monthly;
    default:
      return null;
  }
};

const datesAreConsecutive = (dates: PreprDate[]) => {
  for (let i = 1; i < dates.length; i += 1) {
    if (
      differenceInCalendarDays(
        new Date(dates[i].from),
        new Date(dates[i - 1].from)
      ) !== 1
    ) {
      return false;
    }
  }

  return true;
};

const dateFormat = (date: PreprDate, locale: Locale) =>
  formatDate({
    date: date.until,
    format: 'd MMMM yyyy',
    locale,
  });

const sortDates = (dates: PreprDate[]) =>
  dates.sort(
    (a, b) => new Date(a.until).getTime() - new Date(b.until).getTime()
  );

const getUpcomingDates = (dates: PreprDate[]) =>
  sortDates(
    dates.filter(date => new Date(date.until).getTime() > new Date().getTime())
  );

const hasDates = (dates: PreprDate[]) => !!dates.length;
const hasOneDate = (dates: PreprDate[]) => dates.length === 1;
const hasTwoDates = (dates: PreprDate[]) => dates.length === 2;
const getFirstDate = (dates: PreprDate[]) => dates[0];
const getLastDate = (dates: PreprDate[]) => dates.at(-1);

export default function datesToLocalizedString({
  dates,
  frequency,
  locale,
  showDatesInPast,
}: {
  dates: PreprDate[];
  frequency?: Frequency;
  locale: Locale;
  showDatesInPast?: boolean;
}) {
  const sortedDates = showDatesInPast
    ? sortDates(dates)
    : getUpcomingDates(dates);

  if (!hasDates(sortedDates)) return translations[locale].eventPassed;

  if (hasOneDate(sortedDates)) {
    return dateFormat(getFirstDate(sortedDates), locale);
  }

  if (hasTwoDates(sortedDates)) {
    return `${dateFormat(getFirstDate(sortedDates), locale)} ${
      translations[locale].and
    } ${dateFormat(getLastDate(sortedDates)!, locale)}`;
  }

  if (frequency) {
    return `${getFrequencyTranslation(frequency, locale)} ${
      translations[locale].until
    } ${dateFormat(getLastDate(sortedDates)!, locale)}`;
  }

  if (datesAreConsecutive(sortedDates)) {
    return `${dateFormat(getFirstDate(sortedDates), locale)} ${
      translations[locale].until
    } ${dateFormat(getLastDate(sortedDates)!, locale)}`;
  }

  return `${dateFormat(getFirstDate(sortedDates), locale)} ${
    translations[locale].andMore
  }`;
}
